<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
    <!-- 搜索-->
    <el-row :gutter="20" type="flex" justify="space-between">
      <!-- <el-col>
                  <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
                     <i class="el-icon-plus fw900 f16"></i> 新增</el-button>
              </el-col> -->
      <el-col>
        <div class="flex align-center justify-end header-search-box">
          <el-input
            style="width:250px"
            placeholder="输入运营商/管理员查询"
            clearable
            v-model="searchInput"
            class="header-search-input ml10"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <!-- 用户列表区域 -->
    <el-table
      :data="tableData"
      border
      fit
      :height="height"
      highlight-current-row
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column label="编号" type="index" width="50">
      </el-table-column>
      <el-table-column label="所属电子围栏" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.electronicFenceName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属运营商" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.operatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属管理员" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.administratorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="锁编号" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="经度" align="center" prop="memberCode">
        <template slot-scope="scope">
          <span>{{ scope.row.latitude }}</span>
        </template>
      </el-table-column>
      <el-table-column label="纬度" align="center" prop="memberCode">
        <template slot-scope="scope">
          <span>{{ scope.row.longitude }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" align="center" prop="id" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="主电量" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.electricQuantity }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="设备状态" align="center" prop="orderPrice">
        <template slot-scope="scope">
            <span>{{
                scope.row.deviceStatus === 0 ? "未使用" : "已使用"
              }}</span>
        </template>
      </el-table-column>
      <el-table-column label="录入时间" align="center" prop="memberCode">
        <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{scope.row.createDt | dataFormat }}</span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column label="二维码" align="center" prop="二维码">
        <template slot-scope="scope">
          <el-image
            style="height:60px;width:60px"
            fit="fill"
            :src="scope.row.qrCode"
            :preview-src-list="[...scope.row.qrCode]"
          ></el-image>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--        label="操作"-->
<!--        align="center"-->
<!--        class-name="small-padding fixed-width"-->
<!--        width="140"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--&lt;!&ndash;          <el-tooltip&ndash;&gt;-->
<!--&lt;!&ndash;            class="item"&ndash;&gt;-->
<!--&lt;!&ndash;            effect="dark"&ndash;&gt;-->
<!--&lt;!&ndash;            content="编辑"&ndash;&gt;-->
<!--&lt;!&ndash;            placement="bottom"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <i&ndash;&gt;-->
<!--&lt;!&ndash;              class="el-icon-edit textblue f16 "&ndash;&gt;-->
<!--&lt;!&ndash;              @click="gotoDetail(scope.row.id)"&ndash;&gt;-->
<!--&lt;!&ndash;            ></i>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-tooltip>&ndash;&gt;-->
<!--          <el-tooltip-->
<!--            class="item"-->
<!--            effect="dark"-->
<!--            content="生成二维码"-->
<!--            placement="bottom"-->
<!--          >-->
<!--            <i-->
<!--              class="el-icon-full-screen textblue f16 ml20"-->
<!--              @click="-->
<!--                  createQrcode(-->
<!--                    scope.row.id,-->
<!--                    scope.row.deviceCode,-->
<!--                    scope.row.electronicFenceCode,-->
<!--                    scope.row.deviceId-->
<!--                  )-->
<!--                "-->
<!--            ></i>-->
<!--          </el-tooltip>-->
<!--&lt;!&ndash;          <el-tooltip&ndash;&gt;-->
<!--&lt;!&ndash;            class="item"&ndash;&gt;-->
<!--&lt;!&ndash;            effect="dark"&ndash;&gt;-->
<!--&lt;!&ndash;            content="删除"&ndash;&gt;-->
<!--&lt;!&ndash;            placement="bottom"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <i&ndash;&gt;-->
<!--&lt;!&ndash;              class="el-icon-delete textblue f16 ml20"&ndash;&gt;-->
<!--&lt;!&ndash;              @click="del(scope.row.id)"&ndash;&gt;-->
<!--&lt;!&ndash;            ></i>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-tooltip>&ndash;&gt;-->
<!--          &lt;!&ndash; <el-button type="primary"> 二维码 </el-button> &ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->
      <!-- <el-table-column
                      label="操作"
                      align="center"
                      class-name="small-padding fixed-width"
              >
                  <template slot-scope="scope">
                      <el-tooltip
                              class="item"
                              effect="dark"
                              content="查看详情"
                              placement="bottom"
                      >
                          <el-button type="primary" icon="el-icon-info" circle
                                  @click="gotoDetail(scope.row.id, scope.row.userCode)"
                       ></el-button>
                      </el-tooltip>
                  </template>
              </el-table-column> -->
    </el-table>
    <div class="footerBox">
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",

      loading: true,
      id: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      tableData: [],
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          keyWord: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      this.getListData();
    },

    //状态改变事件
    // changeStatus(row) {

    //   var that=this;
    // //   alert()
    //  that.repairsStatus=1;
    //   that.$http.post("/repairsInfo/save", {repairsStatus:1,id:row.id}
    //   ).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "保存成功",
    //         showClose: true,
    //       });
    //     that.getListData();
    //   }).catch(function(error){
    //     //   console.log("---")
    //       that.$notify.info({
    //         title: "提示",
    //         message: "保存失败",
    //         showClose: true,
    //       });
    //   })

    // },
    judgeStatus(orderStatus) {
      if (orderStatus === 0) {
        return "未完成";
      } else if (orderStatus === 1) {
        return "未支付";
      } else if (orderStatus === 2) {
        return "已完成";
      } else {
        return "";
      }
    },
    createQrcode(id, deviceCode, electronicFenceCode, deviceId) {
      var that = this;
      that.$http
        .post("/WXPay/generateQrcode", {
          deviceCode: deviceCode,
          id: id,
          electronicFenceCode: electronicFenceCode,
          deviceId
        })
        .then(function(response) {
          that.$notify.success({
            title: "提示",
            message: "生成二维码成功",
            showClose: true
          });
          that.getListData();
        });
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/equipment/equipMagDetail",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/device/delete", { id: id }).then(function(response) {
          that.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true
          });
          that.getListData();
        });
      }
    },
    getListData() {
      // 获取列表
      this.queryInfo.condition.keyWord = this.searchInput;
      this.queryInfo.condition.operatorCode = JSON.parse(localStorage.getItem('userInfo')).operatorCode
      var that = this;

      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/device/listOperator", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: that.queryInfo.condition
        })
        .then(function(response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.managingPeople /deep/ .el-icon-circle-close {
  color: white;
}
</style>
